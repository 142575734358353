.spinnerarea {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(300%, 0);
}

.container{
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .some-class {
      left: 50%;
    }
  }
  
  @media (max-width: 1200px) {
    .spinnerarea {
      transform: translate(-50%, 0);
    }
  }